const document = {
    "document.list.title": "Liste des documents",
    "document.transfer.title": "Transfert de documents",
    "document.transfer.submit": "Crypter, Charger et Transférer",
    "document.personal.submit": "Crypter et télécharger",
    "document.list.decrypt.download": "Décrypter et Télécharger",
    "document.list.decrypt.download.tooltip":
        "Décrypter et Télécharger le document",
    "document.decryption.title": "Décryptage",
    "document.decryption.section.title": "Décryptage simples de documents",
    "document.decryption.subtitle":
        "Ici, vous pouvez simplement décrypter un fichier. Veuillez noter que seuls les fichiers cryptés par TracerDoc (fichiers .tracerdoc) peuvent être déchiffrés ici.",
    "document.encryption.submit": "Crypter et Télécharger",
    "document.decryption.submit": "Décrypter",
    "document.list.download": "Télécharger",
    "document.list.download.tooltip": "Télécharger le document crypté",
    "document.encryption.title": "Cryptage de document",
    "document.encryption.subtitle":
        "Choisissez un document et cryptez-le avec la clé de votre partenaire.",
    "document.transfer.mode": "Mode de transfert",
    "document.transfer.mode.upload.desc":
        "Envoyer le document crypté via IPFS et le transférer au partenaire.",
    "document.transfer.mode.download.desc":
        "Téléchargez simplement le document crypté.",
    "document.transfer.mode.upload": "Télécharger",
    "document.transfer.mode.upload.and.transfer": "Charger et Transférer",
    "document.transfer.mode.download": "Téléchargement simple",
    "document.transfer.type.public": "Publique",
    "document.transfer.type.private.ipfs": "Private IPFS",
    "document.transfer.type.private": "Privée",
    "document.transfer.type": "Type d'IPFS",
    "documents.all": "All Documents",
    "document.public.storage.max.limit":
        "Public storage has reached its maximum file limit!",
    "document.private.storage.max.limit":
        "Private storage has reached its maximum file limit! ! Upgrade your subscription to increase your volume.",
    "document.upgrade.license":
        "Upgrade your license to upload and transfer documents.",
    "document.download.decrypt": "Télécharger et décrypter",
    "document.properties": "Propriétés du document",
    "document.fileName": "Filename",
    "document.fileSize": "Size",
    "document.securityLevel": "Security Level",
    "document.ipfsType": "IPFS Type",
    "document.cid": "Document CID",
    "document.sender.cid": "Sender Key CID",
    "document.recipient.cid": "Recipient Key CID",
    "document.created": "Créé",
    "document.expiresAt": "Will expire on",
    "document.modified": "Modifié",
    "document.category": "Catégorie",
    "document.subcategory": "Sous-catégorie",
    "document.categories": "Catégories",
    "document.sender": "Expéditeur",
    "document.recipient": "Destinataire",
    "document.status": "Statut",
    "document.transfer.new": "Ajouter un Nouveau Document",
    "document.download.url": "Download URL",
    "document.remove.filter":
        "Cliquez pour supprimer le filtre pour afficher toute la liste",
    "document.alert.remove.filter":
        "Cliquez sur l'icône de filtre ci-dessous pour afficher la liste des transferts de documents.",

    "document.download.only": "Download Only",
    "document.select.action": "Select Action",
    "document.upload.files": "Upload tracerdoc file and key",
    "document.upload.zip": "Upload zip file",
    "documents.personal": "Internal Storage",
    "document.no.storage": "No Storage",
    "document.add.new.modal.title": "Add New Document",
    "document.download.encrypted.file": "Download Encrypted File",
    "document.store.encrypted.file": "Store Encrypted File",
    "document.store.share.encrypted.file": "Store and Share Encrypted File",
    "document.download.shareable.file": "Download the shareable encrypted file",
    "document.remove": "Remove file",
    "document.no.partner": "No Partner - Only for me",
    "document.duration": "Expiration",
    "document.unlimited": "Unlimited",
    "document.d001": "1 day (24 hours)",
    "document.d003": "3 days (72 hours)",
    "document.d007": "7 days (168 hours)",
    "document.d030": "30 days (720 hours)",
    "document.permanent.storage": "* Permanent Storage",
    "document.storage.after.upload": "Storage available after upload :",
    "document.auto.remove.activated": "* Auto remove activated at",
    "document.not.available.free.license": "Not available for free license",
};

export default document;
