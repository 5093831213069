const authentification = {
    "auth.signin.link": "Sign In",
    "auth.signup.link": "Sign Up",
    "auth.title": "TracerDoc - Sign-In with the 2FA",
    "auth.signin.title": "Continue authentication with your wallet",
    "auth.signin.subtitle":
        "Connect with one of our available wallet info providers.",
    "auth.signin.id.two.factor.auth":
        "Login with your ID (email and password) and TracerKey",
    "auth.signin.id.two.factor.auth.mobile":
        "Login with your ID  and TracerKey",
    "auth.signin.tracerkey": "Connect with TracerKey",
    "auth.signup.title": "Sign up with your Wallet",
    "auth.signup.subtitle":
        "Register with one of our available wallet info providers.",
    "auth.already.have.account": "Already have an account ?",
    "auth.no.account": "No account ?",
    "auth.signup.privacy.policy": "Privacy Policy.",
    "auth.signup.privacy.policy.desc":
        "You agree to the Terms and Conditions of TracerDoc's Site Policy and to Tracerchain's",
    "auth.signup.privacy.policy.desc.2": " ",
    "auth.signup.terms.title": "Terms and Conditions",
    "auth.signup.terms.confirm.desc":
        "By ticking this box, you are confirming that you have read and agree to Tracerdoc's",
    "auth.signup.terms.tos": "Terms of Service",
    "auth.signup.terms.privacy.policy": "Privacy Policy",
    "auth.signup.terms.cancel": "Cancel",
    "auth.signup.terms.continue": "Continue",
    "auth.account.confirmation.title": "TRACERDOC ACCOUNT CONFIRMATION",
    "auth.account.confirmation.subtitle":
        "Click on the button below to confirm and activate your account.",
    "auth.account.confirmed": "ACCOUNT CONFIRMED",
    "auth.resend.email": "Resend confirmation email",
    "auth.resend.email.title": "Resend an activation email",
    "auth.resend.email.text":
        "Enter your email address to resend the activation email.",
    "auth.confirmation.email.sent.title": "CONFIRMATION EMAIL SENT",
    "auth.confirmation.email.sent.subtitle":
        "Please confirm your account. A confirmation email was sent to {email}, click on the link to confirm your account.",
    "auth.logout": "Logout",
    "auth.manage.account": "Manage Profile",
    "auth.signin.view.doc.transfer.alert":
        "You must login to your account to view the document transfer.",
    "auth.wallet.view.doc.transfer.alert":
        "You must connect your tracerkey account to view the document transfer.",
    "auth.install.tracerkey": "How to install tracerkey?",
    "auth.support.help.center": "Support - Help Center",
    "logout.title": "Logging you out...",
};

export default authentification;
