import { gql } from "@apollo/client";
import { PARTNERSHIP_FRAGMENT, PARTNERSHIP_REQUEST_FRAGMENT } from "./fragment";

export const GET_MY_PARTNERS = gql`
    query MyPartners {
        myPartners {
            ...PartnershipFields
            partner {
                id
                email
                activated
                wallet {
                    address
                    encryptionPublicKey
                    pqEncryptionPublicKey
                    sigPublicKey
                }
            }
        }
    }
    ${PARTNERSHIP_FRAGMENT}
`;

export const GET_MY_PARTNERSHIP_REQUESTS = gql`
    query MyPartnershipRequests($status: RequestStatus) {
        myPartnershipRequests(status: $status) {
            ...PartnershipRequestFields
            sender {
                id
                email
            }
            recipient {
                id
                email
            }
        }
    }
    ${PARTNERSHIP_REQUEST_FRAGMENT}
`;
