import { FC, useContext, useState, useEffect, memo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Grid, IconButton } from "@material-ui/core";
import { Space, Tooltip, Alert, Select } from "antd";
import { useHistory } from "react-router-dom";
import { useIntl } from "react-intl";
import { RangeValue, RequestStatus } from "../../../constants/types";
import { GET_MY_DOCUMENT_TRANSFER } from "../../../graphql/document/query";
import { MyDocumentTransfers_myDocumentTransfers } from "../../../graphql/document/types/MyDocumentTransfers";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { MyDocumentTransfers_myDocumentTransfers_documents } from "../../../graphql/document/types/MyDocumentTransfers";
import { MyDocumentTransfer } from "../../../graphql/document/types/MyDocumentTransfer";
import { MyDocumentTransferVariables } from "../../../graphql/document/types/MyDocumentTransfer";
import { retrieveFromIPFS } from "../../../utils/ipfs";
import { ethDecrypt } from "../../../services/tracerkey";
import { ACKNOWLEDGE_RECEIPT } from "../../../graphql/document/mutation";
import { AcknowledgeReceiptVariables } from "../../../graphql/document/types/AcknowledgeReceipt";
import { AcknowledgeReceipt } from "../../../graphql/document/types/AcknowledgeReceipt";
import { ROUTES } from "../../../constants/routes";
import { DOCUMENT_TAB_ROUTES } from "../Document";
import { FaFilter } from "react-icons/fa";
import { generateArmoredFileName } from "../../../utils/document";
import { generatePassphraseFileName } from "../../../utils/document";
import { saveAs } from "file-saver";
import { filterListByDate } from "../../../components/DateFilter/DateFilter";
import { zipBlobs } from "../../../utils/document";
import { LOCAL_STORAGE_KEYS } from "../../../constants/localStorage";
import * as openpgp from "openpgp";
import CustomPaper from "../../../components/CustomPaper/CustomPaper";
import FilterChips from "../../../components/FilterChips/FilterChips";
import CustomAntdTable from "../../../components/CustomAntdTable/CustomAntdTable";
import SearchFilter from "../../../components/SearchFilter/SearchFilter";
import DateFilter from "../../../components/DateFilter/DateFilter";
import DocumentInfo from "./DocumentInfo";
import TableLoader from "../../../components/TableLoader/TableLoader";
import columns from "./DocumentTableColumns";
import useSearchParams from "../../../customHooks/useSearchParams/useSearchParams";
import useBreakpoint from "../../../customHooks/useBreakpoint/useBreakpoint";
import useStyles from "../styles";

const { Option } = Select;

interface IProps {
    data: MyDocumentTransfers_myDocumentTransfers | undefined;
    filter: string;
    setFilter(filter: string): void;
    queryLoading: boolean;
    refetch?: any;
    onPageChange(page: number, pageSize: number): void;
    page: number;
    pageSize: number;
}

const DocumentList: FC<IProps> = (props) => {
    const classes = useStyles();
    const breakpoint = useBreakpoint();
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const { data, queryLoading, refetch, filter, setFilter } = props;
    const { page, pageSize, onPageChange } = props;
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState<string>("");
    const [dateFilter, setDateFilter] = useState<string>("all");
    const [openDateFilter, setOpenDateFilter] = useState<boolean>(false);
    const [dateRange, setDateRange] = useState<RangeValue>(null);
    const [localToken] = useState<string | null>(
        localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN)
    );

    const history = useHistory();
    const searchParams = useSearchParams();

    const paramsId = searchParams.get("id");
    const isRedirectParams = searchParams.get("redirect");

    const [openDocInfo, setOpenDocInfo] = useState(false);
    const [selectedDoc, setSelectedDoc] =
        useState<MyDocumentTransfers_myDocumentTransfers_documents | null>(
            null
        );
    const token = localToken;

    const { data: documentTransfer, loading: myQueryLoading } = useQuery<
        MyDocumentTransfer,
        MyDocumentTransferVariables
    >(GET_MY_DOCUMENT_TRANSFER, {
        variables: { id: paramsId && isRedirectParams ? Number(paramsId) : 0 },
        skip: paramsId && isRedirectParams ? false : true,
    });

    useEffect(() => {
        const { myDocumentTransfer } = documentTransfer || {};
        if (
            paramsId &&
            isRedirectParams &&
            !myQueryLoading &&
            myDocumentTransfer
        ) {
            setSelectedDoc(myDocumentTransfer);
            setOpenDocInfo(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRedirectParams, myQueryLoading]);

    const [acknowledgeReceipt] = useMutation<
        AcknowledgeReceipt,
        AcknowledgeReceiptVariables
    >(ACKNOWLEDGE_RECEIPT, { onError: (error) => onError(error?.message) });

    const onClickFilter = (tab: string) => setFilter(tab);

    const onError = (errorMsg?: string) => {
        displaySnackBar({
            message: formatMessage({ id: errorMsg || "error.unknown" }),
            type: "error",
        });
    };

    const handleDownload = async (
        documentTransfer: MyDocumentTransfers_myDocumentTransfers_documents
    ) => {
        try {
            const pgpFile = await retrieveFromIPFS({
                cid: documentTransfer?.document?.cid || "",
                fileName: documentTransfer.fileName || "",
                token,
            });
            const pgpFileName = generateArmoredFileName(pgpFile.name);
            const buffer1 = await pgpFile.arrayBuffer();
            const pgpFileBlob = new Blob([buffer1]);

            const myPassphraseHash = documentTransfer.sentToMe
                ? documentTransfer?.recipientPassphrase?.cid
                : documentTransfer?.senderPassphrase?.cid;
            const myPartnerPassphraseHash = !documentTransfer.sentToMe
                ? documentTransfer?.recipientPassphrase?.cid
                : documentTransfer?.senderPassphrase?.cid;

            const myArmoredPassphraseFile = await retrieveFromIPFS({
                cid: myPassphraseHash || "",
                fileName: "passphrase.txt",
                token,
            });

            const myArmoredPassphraseText =
                await myArmoredPassphraseFile.text();

            const myArmoredPassphraseTextParsed = JSON.parse(
                myArmoredPassphraseText
            );

            const myDecryptedPassphrase = await ethDecrypt(
                myArmoredPassphraseTextParsed?.ciphertext,
                myArmoredPassphraseTextParsed?.shared_key_ciphertext
            );

            if (!myDecryptedPassphrase?.plaintext) {
                onError("error.document.decryption");
                return;
            }

            const parsedDecryptedPassphrase = JSON.parse(
                myDecryptedPassphrase?.plaintext
            );

            const myEncryptedPassphraseFileBuffer =
                await myArmoredPassphraseFile.arrayBuffer();
            const myEncryptedPassphraseBlob = new Blob([
                myEncryptedPassphraseFileBuffer,
            ]);

            const partnerPassphraseFile = await retrieveFromIPFS({
                cid: myPartnerPassphraseHash || "",
                fileName: "passphrase.txt",
                token,
            });

            const fileName: string = documentTransfer?.fileName || "";
            const wallet: string | undefined = !documentTransfer?.sentToMe
                ? documentTransfer?.recipient?.wallet?.address || ""
                : documentTransfer?.sender?.wallet?.address || "";

            const partnerEncyptedArmoredFilename = generatePassphraseFileName(
                fileName,
                wallet
            );
            const partnerPassphraseFileBuffer =
                await partnerPassphraseFile.arrayBuffer();
            const partnerEncrypedPassphraseBlob = new Blob([
                partnerPassphraseFileBuffer,
            ]);

            let blobs = [
                { blob: pgpFileBlob, fileName: pgpFileName },
                {
                    blob: myEncryptedPassphraseBlob,
                    fileName: parsedDecryptedPassphrase?.armoredFileName,
                },
                // {
                //     blob: partnerEncrypedPassphraseBlob,
                //     fileName: partnerEncyptedArmoredFilename,
                // },
            ];
            if (documentTransfer?.recipient) {
                blobs.push({
                    blob: partnerEncrypedPassphraseBlob,
                    fileName: partnerEncyptedArmoredFilename,
                });
            }

            zipBlobs(blobs).then((blob: any) => {
                saveAs(blob, `${documentTransfer?.fileName || ""}.tdPack.zip`);
            });
        } catch (err) {
            console.log(err);
        }
    };

    const decryptAndDownload = async (
        documentTransfer: MyDocumentTransfers_myDocumentTransfers_documents
    ) => {
        setLoading(true);
        if (!openDocInfo) {
            setSelectedDoc(documentTransfer);
        }

        const myPassphraseHash = documentTransfer.sentToMe
            ? documentTransfer?.recipientPassphrase?.cid
            : documentTransfer?.senderPassphrase?.cid;

        try {
            const myArmoredPassphraseFile = await retrieveFromIPFS({
                cid: myPassphraseHash || "",
                fileName: "passphrase.txt",
                token,
            });

            const pgpFile = await retrieveFromIPFS({
                cid: documentTransfer?.document?.cid || "",
                fileName: "armored.txt",
                token,
            });

            const myArmoredPassphraseText =
                await myArmoredPassphraseFile.text();

            const myArmoredPassphraseTextParsed = JSON.parse(
                myArmoredPassphraseText
            );

            const myDecryptedPassphrase = await ethDecrypt(
                myArmoredPassphraseTextParsed?.ciphertext,
                myArmoredPassphraseTextParsed?.shared_key_ciphertext
            );

            if (!myDecryptedPassphrase?.plaintext) {
                onError("error.document.decryption");
                return;
            }

            if (documentTransfer?.sentToMe) {
                acknowledgeReceipt({
                    variables: { input: { id: documentTransfer?.id } },
                });
            }

            const parsedDecryptedPassphrase = JSON.parse(
                myDecryptedPassphrase?.plaintext
            );

            const passphrase: string = parsedDecryptedPassphrase?.armored;
            // const passphraseFileName: string = myDecryptedPassphraseObject?.fileName;

            const encryptedMessage = await openpgp.readMessage({
                armoredMessage: await pgpFile.text(), // parse encrypted bytes
            });

            const { data, filename } = await openpgp.decrypt({
                message: encryptedMessage,
                passwords: [passphrase],
                format: "binary",
            });

            const decryptedContent = data as any;

            const arrayBytes = Object.keys(decryptedContent).map(
                (val: any) => decryptedContent[val]
            );

            const docContentBytes = new Uint8Array(arrayBytes);

            const blob = new Blob([docContentBytes]);
            var fileURL = window.URL.createObjectURL(blob);

            const tempLink = document.createElement("a");
            tempLink.href = fileURL;
            tempLink.setAttribute("download", filename);
            tempLink.click();
            setLoading(false);
            if (!openDocInfo) setSelectedDoc(null);
        } catch (error) {
            console.log(error);
            onError("error.document.retrieve.decrypt");
            setLoading(false);
            setSelectedDoc(null);
        }
    };

    const viewDocInfo = (
        open: boolean,
        document: MyDocumentTransfers_myDocumentTransfers_documents | null
    ) => {
        setOpenDocInfo(open);
        setSelectedDoc(document);
        if (open) {
            history.push({
                pathname: `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}`,
                search: `?id=${document?.id}${
                    isRedirectParams ? "&redirect=true" : ""
                }`,
            });
        }
        // history.push(`${ROUTES.DOCUMENTS}/list/${document?.id}`);
    };

    const toggleDateFilter = (open: boolean) => {
        setOpenDateFilter(open);

        if (!open && dateFilter === "custom" && !dateRange) {
            setDateFilter("all");
        }
    };

    const handleDateFilter = (type: string) => {
        setDateFilter(type);
        if (type !== "custom") setDateRange(null);
    };

    const myDocumentTransfers = data?.documents || [];
    let filteredDocs =
        paramsId && isRedirectParams && documentTransfer?.myDocumentTransfer
            ? [documentTransfer?.myDocumentTransfer]
            : myDocumentTransfers?.length
            ? myDocumentTransfers?.filter((doc) => {
                  const _search = search?.toLowerCase();
                  return (
                      doc?.fileName?.toLowerCase().startsWith(_search) ||
                      doc?.sender?.email?.toLowerCase().startsWith(_search) ||
                      doc?.recipient?.email
                          ?.toLowerCase()
                          .startsWith(_search) ||
                      doc?.ipfsType?.toLowerCase().startsWith(_search)
                  );
              })
            : null;

    filteredDocs = filteredDocs?.length
        ? filterListByDate(filteredDocs, dateFilter, dateRange, "createdAt")
        : [];

    return (
        <>
            {paramsId && isRedirectParams && !openDocInfo && (
                <div className="w-100 my-4">
                    <Alert
                        description={formatMessage({
                            id: "document.alert.remove.filter",
                        })}
                        type="info"
                        showIcon
                    />
                </div>
            )}

            <CustomPaper>
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className="my-2"
                    spacing={2}
                >
                    <Grid item xs={12} sm={6}>
                        {breakpoint === "xs" ? (
                            <Select
                                value={filter}
                                onChange={onClickFilter}
                                allowClear
                                showSearch
                                optionFilterProp="children"
                                className="antdSelect"
                            >
                                {[
                                    "documents.all",
                                    "common.sent.to.me",
                                    "common.sent.by.me",
                                    "documents.personal",
                                ].map((item, idx) => (
                                    <Option value={item} key={idx}>
                                        {formatMessage({
                                            id: item,
                                        })}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            <Space>
                                {[
                                    "documents.all",
                                    "common.sent.to.me",
                                    "common.sent.by.me",
                                    "documents.personal",
                                ].map((item, idx) => (
                                    <FilterChips
                                        key={idx}
                                        text={item}
                                        type={RequestStatus.ALL}
                                        onClick={onClickFilter}
                                        selected={filter === item}
                                    />
                                ))}
                            </Space>
                        )}
                    </Grid>
                    {!queryLoading && (
                        <Grid item className="d-flex justify-content-end">
                            <Space>
                                <Tooltip
                                    title={formatMessage({
                                        id: "document.remove.filter",
                                    })}
                                    visible={
                                        paramsId &&
                                        isRedirectParams &&
                                        !openDocInfo
                                            ? true
                                            : false
                                    }
                                >
                                    <IconButton
                                        disabled={
                                            !isRedirectParams && !paramsId
                                        }
                                        style={{
                                            background:
                                                paramsId && isRedirectParams
                                                    ? "#F1F4F5"
                                                    : "",
                                        }}
                                        onClick={() => {
                                            history.push(
                                                `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}`
                                            );
                                        }}
                                    >
                                        <FaFilter
                                            style={{
                                                fontSize: 14,
                                                color:
                                                    paramsId && isRedirectParams
                                                        ? "#236CA5"
                                                        : "#9E9EA1",
                                            }}
                                        />
                                    </IconButton>
                                </Tooltip>
                                <SearchFilter
                                    value={search}
                                    setValue={setSearch}
                                    results={filteredDocs?.length}
                                />
                                <DateFilter
                                    openDateFilter={openDateFilter}
                                    dateFilter={dateFilter}
                                    toggleDateFilter={toggleDateFilter}
                                    handleDateFilter={handleDateFilter}
                                    dateRange={dateRange}
                                    setDateRange={setDateRange}
                                />
                            </Space>
                        </Grid>
                    )}
                </Grid>

                {queryLoading ||
                (myQueryLoading && paramsId && isRedirectParams) ? (
                    <div style={{ marginTop: "-1em" }}>
                        <TableLoader />
                    </div>
                ) : (
                    <div className="mt-2">
                        <CustomAntdTable
                            data={filteredDocs}
                            rowKey="id"
                            childrenColumnName="subCategories"
                            pagination
                            onPageChange={onPageChange}
                            selectedKey="id"
                            selectedRow={selectedDoc}
                            totalCount={data?.totalCount}
                            columns={columns(
                                filter,
                                viewDocInfo,
                                classes,
                                decryptAndDownload,
                                loading,
                                selectedDoc,
                                formatMessage,
                                page,
                                pageSize
                            )}
                        />
                    </div>
                )}
            </CustomPaper>
            <DocumentInfo
                open={openDocInfo}
                setOpen={viewDocInfo}
                document={selectedDoc}
                refetch={refetch}
                decryptAndDownload={decryptAndDownload}
                downloadLoader={loading}
                isRedirectParams={isRedirectParams}
                download={handleDownload}
            />
        </>
    );
};

export default memo(DocumentList);
