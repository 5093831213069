import { gql } from "@apollo/client";
import { USER_FRAGMENT } from "./fragment";

export const GET_ME = gql`
    query GetMe {
        me {
            ...UserFields
            wallet {
                address
                encryptionPublicKey
                pqEncryptionPublicKey
                sigPublicKey
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const GET_USERS = gql`
    query GetUsers($input: GetUsersInput) {
        getUsers(input: $input) {
            ...UserFields
            wallet {
                address
                encryptionPublicKey
            }
        }
    }
    ${USER_FRAGMENT}
`;

export const GET_USER = gql`
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            email
        }
    }
`;

export const GET_MY_PLAN = gql`
    query GetMyPlan {
        myPlan {
            name
            amount
            active
            interval
            currency
        }
    }
`;

export const IS_EMAIL_REGISTRED = gql`
    query IsEmailRegistred($email: String!) {
        isEmailRegistred(email: $email)
    }
`;

export const GET_REFERENCE_ID = gql`
    query ReferenceId {
        referenceId
    }
`;
