import { FC } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Typography, makeStyles, createStyles } from "@material-ui/core";
import { Space } from "antd";
import { useIntl } from "react-intl";
import { ROUTES } from "../../constants/routes";
import nodataImg from "../../images/not_found.svg";
import CustomButton from "../../components/CustomButton/CustomButton";
import PageContainer from "../../components/PageContainer/PageContainer";

const useStyles = makeStyles(() =>
    createStyles({
        font14: { fontSize: 14 },
        notFound: { fontWeight: 500, marginTop: "1em" },
    })
);

const NotFoundPage: FC<RouteComponentProps> = ({ history }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();

    const backToSite = () => {
        history.replace(ROUTES.HOME);
    };

    return (
        // <PageContainer isPublicPage title="Page Not Found">
        <PageContainer isPublicPage title="Page Not Found">
            <img src={nodataImg} height="230px" width="auto" alt="no data" />
            <Space direction="vertical" align="center" className="mt-4">
                <Typography className={classes.notFound}>
                    {formatMessage({ id: "error.page.not.found.title" })}
                </Typography>
                <Typography className={classes.font14}>
                    {formatMessage({ id: "error.page.not.found.subtitle" })}
                </Typography>
                <CustomButton
                    onClick={backToSite}
                    text={formatMessage({ id: "error.page.not.found.button" })}
                />
            </Space>
        </PageContainer>
    );
};

export default withRouter(NotFoundPage);
