import { createStyles, makeStyles, Theme } from "@material-ui/core";
import COLORS from "../../constants/colors";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            minHeight: "100vh",
            background: COLORS.white,
        },
        container: {
            minHeight: "88vh",
            display: "flex",
            alignItems: "center",
        },
        disabled: {},
        subtitle: {
            fontSize: 24,
        },
        imgLogo: {
            margin: "auto",
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.secondary.main,
        },
        formGridItem: {
            padding: "0 2em !important",
        },
        form: {
            marginTop: theme.spacing(1),
        },
        submit: {
            margin: theme.spacing(3, 0, 2),
        },
        paper: {
            height: "500px",
            marginTop: "-3.5em",
        },
        children: {
            margin: theme.spacing(-5, 6, 7, 6),
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            justifyContent: "center",
            height: "100%",
        },

        globalText: {
            color: COLORS.blue,
            fontWeight: "bold",
            [theme.breakpoints.only("xs")]: {
                fontSize: "16px",
                textAlign: "center",
            },
        },
        childrenTitle: {
            color: COLORS.black,
            fontWeight: 500,
            textAlign: "center",
            [theme.breakpoints.only("xs")]: {
                textAlign: "center",
                color: COLORS.black100,
                fontSize: "14px",
            },
        },
        desc1: {
            marginBottom: "2em",
            [theme.breakpoints.only("sm")]: {
                fontSize: "22px",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "27px",
            },
        },
        compliance: {
            [theme.breakpoints.only("sm")]: {
                display: "none",
            },
        },
        language: {
            display: "flex",
            justifyContent: "start",
            width: "100%",
            paddingLeft: "1em",
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
        },
        submitBtn: {
            width: "100%",
            background: COLORS.primaryGradient,
            color: COLORS.white,
            borderRadius: 25,
            fontSize: 14,
            paddingRight: "1.5em",
            paddingLeft: "1.5em",
            marginTop: "2em",
            "&:hover": {
                transform: "scale(1.03)",
                background: `${COLORS.primaryGradientLight} !important`,
                border: `1px solid ${COLORS.primaryDark}`,
                color: COLORS.white,
            },
            "&:disabled": {
                background: COLORS.white100,
            },
            boxShadow: "0px 8px 20px -6px rgba(0,0,0,0.15)",
            [theme.breakpoints.down("sm")]: {
                fontSize: 14,
            },
        },
        transferVectorCont: {
            marginTop: "-3em",
        },
        transferVector: {
            height: "400px",
            width: "auto",
            [theme.breakpoints.only("md")]: {
                height: "360px",
                width: "auto",
            },
            [theme.breakpoints.only("sm")]: {
                height: "200px",
                width: "auto",
            },
            [theme.breakpoints.only("xs")]: {
                display: "none",
            },
        },
        main: {
            [theme.breakpoints.only("xs")]: {
                display: "none",
            },
        },
        installLink: {
            color: COLORS.blue300,
            fontSize: 13,
        },
        alertWarning: {
            border: "1px solid orange",
        },
        iconBtn: {
            background: "transparent",
            marginTop: "-3px",
        },
        font14: {
            fontSize: 14,
        },
        notFound: {
            fontWeight: 500,
            marginTop: "1em",
        },
        languageOptions: {
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
        },
    });
});

export default useStyles;
