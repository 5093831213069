import { FC, useContext, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import { isConnected, tracerkeyConnect } from "../../services/tracerkey";
import { mlkDecrypt } from "../../services/tracerkey";
import { signAuthMessageTracerkey } from "../../services/tracerkey";
import { tracerkeyCheckExtension } from "../../services/tracerkey";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { useMutation } from "@apollo/client";
import { WALLET_AUTH } from "../../graphql/authentification/mutation";
import { WalletAuthVariables } from "../../graphql/authentification/types/WalletAuth";
import { WalletAuth } from "../../graphql/authentification/types/WalletAuth";
import { DOCUMENT_TAB_ROUTES } from "../Document/Document";
import { WalletAuthAction } from "../../types/graphql-global-types";
import { AuthContext } from "../../providers/AuthProvider/AuthProvider";
import { AuthContext as Oauth2Context } from "react-oauth2-code-pkce";
import { DEVID } from "../../constants/devIdEndpoints";
import config from "../../config/config";
import useStyles from "./styles";
import IdAccount from "../../components/IdAccount/IdAccount";
import CustomButton from "../../components/CustomButton/CustomButton";
import AuthLayout from "./AuthLayout";
import axios from "axios";

const WalletAuthPage: FC<RouteComponentProps> = ({ history, location }) => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { login } = useContext(AuthContext);
    const { meId } = useContext(UserContext);
    const { displaySnackBar } = useContext(SnackBarContext);
    const [isTracerkeyInstalled, setIsTracerkeyInstalled] = useState(false);
    const { token: accessToken } = useContext(Oauth2Context);

    const locationState =
        location?.state && typeof location?.state === "string"
            ? JSON.parse(`${location?.state}`)
            : "";
    const locationStateFrom = locationState?.from || "";

    const displayError = (messageId: string) => {
        displaySnackBar({
            message: formatMessage({ id: messageId }),
            type: "error",
        });
    };

    useEffect(() => {
        (async () => {
            const installed = await tracerkeyCheckExtension();
            setIsTracerkeyInstalled(installed);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [walletAuth, { loading }] = useMutation<
        WalletAuth,
        WalletAuthVariables
    >(WALLET_AUTH, {
        onCompleted: async (data) => {
            if (data.walletAuth.token) {
                isConnected(true);
                login(data.walletAuth.token);

                if (
                    locationStateFrom &&
                    locationStateFrom?.pathname?.includes(ROUTES.DOCUMENTS) &&
                    locationStateFrom?.search
                ) {
                    history.replace(
                        `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}${locationStateFrom?.search}`
                    );
                } else if (location?.search && !location?.state) {
                    history.replace(
                        `${ROUTES.DOCUMENTS}/${DOCUMENT_TAB_ROUTES.LIST}${location?.search}`
                    );
                } else {
                    history.replace(ROUTES.DASHBOARD);
                }
                // SIGNIN
                if (data.walletAuth.action === WalletAuthAction.SIGNIN) {
                    displaySnackBar({
                        message: formatMessage({ id: "success.login" }),
                        type: "success",
                    });
                }
                // SIGNUP
                if (
                    data.walletAuth.action === WalletAuthAction.SIGNUP &&
                    data.walletAuth.createdUserId
                ) {
                    displaySnackBar({
                        message: formatMessage({ id: "success.signup" }),
                        type: "success",
                    });
                }
            }
        },

        onError: async (error) => {
            console.log(error?.message);
            displaySnackBar({
                message: error?.message
                    ? error?.message
                    : formatMessage({
                          id: error.message || "error.unknown",
                      }),
                type: "error",
            });
        },
    });

    const onClickWalletAuth = async () => {
        if (meId && meId?.email && meId?.sub) {
            const { publicKey: public_key, encapsulation_key } =
                await tracerkeyConnect();

            const clientChallengeUrl = `${config.id.walletUrl}${DEVID.CLIENTCHALLENGE}`;
            const headers = { Authorization: `Bearer ${accessToken}` };
            const clientChallengeData = { public_key, encapsulation_key };

            try {
                const clientChallengeResult = await axios.post(
                    clientChallengeUrl,
                    clientChallengeData,
                    { headers }
                );

                const {
                    challenge,
                    quantum_challenge_cippertext,
                    shared_key_ciphertext,
                } = clientChallengeResult?.data || {};

                if (challenge) {
                    const decryptedQuantumChallenge = await mlkDecrypt(
                        quantum_challenge_cippertext,
                        shared_key_ciphertext
                    );
                    const quantum_challenge =
                        decryptedQuantumChallenge?.plaintext;

                    if (quantum_challenge) {
                        const result = await signAuthMessageTracerkey(
                            displayError,
                            challenge
                        );

                        if (result) {
                            const { message, signature } = result || {};
                            const clientUrl = `${config.id.walletUrl}${DEVID.CLIENT}`;

                            const clientData = {
                                public_key,
                                challenge,
                                signature,
                                encapsulation_key,
                                quantum_challenge,
                                name: "My wallet",
                            };

                            const clientRes = await axios.post(
                                clientUrl,
                                clientData,
                                { headers }
                            );

                            console.log(clientRes);
                            if (clientRes?.status === 200) {
                                walletAuth({
                                    variables: {
                                        input: {
                                            message,
                                            signature,
                                            email: meId?.email,
                                            keycloakUserId: meId?.sub,
                                        },
                                    },
                                });
                            }
                        }
                    }
                }
            } catch (error) {
                console.error("Error creating challenge:", error);
                // setResponse(error.response ? error.response.data : 'Error');
            }
        }
    };

    // const getChallenge = async () => {
    //     const { address } = await tracerkeyConnect();

    //     console.log(address);

    //     const url = `${config.id.walletUrl}${DEVID.CLIENTCHALLENGE}`;
    //     // const wallet = ethers.Wallet.createRandom();
    //     const headers = {
    //         Authorization: `Bearer ${accessToken}`,
    //         // subject: meId?.sub,
    //         // "Content-Type": "application/json",
    //     };
    //     console.log(headers);
    //     const data = { address };

    //     try {
    //         const result = await axios.post(url, data, { headers });
    //         // setResponse(result.data);
    //         const { challenge } = result?.data || {};
    //         console.log(challenge);

    //         if (challenge) {
    //         }
    //     } catch (error) {
    //         console.error("Error creating challenge:", error);
    //         // setResponse(error.response ? error.response.data : 'Error');
    //     }
    // };

    // const importWallet = async () => {
    //     const url = `${config.id.walletUrl}${DEVID.SERVERIMPORT}`;
    //     const wallet = ethers.Wallet.createRandom();
    //     const headers = {
    //         Authorization: `Bearer ${accessToken}`,
    //         // subject: meId?.sub,
    //         "Content-Type": "application/json",
    //     };
    //     console.log("Headers", headers);
    //     const data = {
    //         name: "3",
    //         private_key: wallet.privateKey,
    //     };

    //     try {
    //         const result = await axios.post(url, data, { headers });
    //         // setResponse(result.data);
    //         console.log(result);
    //     } catch (error) {
    //         console.error("Error importing wallet:", error);
    //         // setResponse(error.response ? error.response.data : 'Error');
    //     }
    // };

    const logoutId = (): Promise<void> => {
        return new Promise((resolve, reject) => {
            try {
                localStorage.clear();
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };

    const devIdLogout = async () => {
        logoutId().then(async () => {
            window.location.href = `${
                config?.id?.baseUrl
            }/logout?next_url=${`${config.uiUrl}${ROUTES.LOGOUT}`}`;
        });
    };

    return (
        <AuthLayout
            type="wallet-auth"
            title="auth.signin.title"
            noTracerkey={!isTracerkeyInstalled}
            pageTitle="Connect Wallet"
        >
            <IdAccount />

            <form className={classes.form} noValidate>
                <CustomButton
                    onClick={devIdLogout}
                    text={formatMessage({
                        id: "common.id.logout",
                    })}
                    disabled={loading}
                    bordered
                    fullWidth
                />
                <CustomButton
                    onClick={onClickWalletAuth}
                    text={formatMessage({
                        id: "auth.signin.tracerkey",
                    })}
                    fullWidth
                    loading={loading}
                    disabled={!isTracerkeyInstalled}
                />

                <div className="d-flex justify-content-end mt-4">
                    <a
                        className={classes.installLink}
                        href={config.tracerkeyInstallationUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {formatMessage({ id: "auth.install.tracerkey" })}
                    </a>
                </div>
            </form>
        </AuthLayout>
    );
};

export default withRouter(WalletAuthPage);
