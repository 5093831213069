export enum ROUTES {
    SIGNIN = "/signin",
    SIGNUP = "/signup",
    WALLET_AUTH = "/wallet-auth",
    ACCOUNT_CONFIRMATION = "/account-confirmation",
    CONFIRMATION_EMAIL_SENT = "/confirmation-email-sent",
    DASHBOARD = "/dashboard",
    HOME = "/home",
    PARTNERSHIP = "/partnership",
    DOCUMENTS = "/documents",
    IPFS = "/ipfs-config",
    PLAN = "/plan",
    BACKUP_RECOVER = "/backup-recover",
    MY_ACCOUNT = "/my-account/subscription",
    SUBSCRIPTION = "/subscription",
    NOT_FOUND = "/not-found",
    CATEGORIES = "/categories",
    LOGOUT = "/logout",
    ADMIN = "/bo",
    ADMIN_DASHBOARD = "/bo/dashboard",
    ADMIN_USERS = "/bo/users",
    ADMIN_USERS_TX = "/bo/users-tx",
    ADMIN_SETTINGS = "/bo/settings",
    ADMIN_SETTINGS_ADMIN_LIST = "/bo/settings/admins",
    ADMIN_SETTINGS_LICENSES = "/bo/settings/licenses",
    ADMIN_SETTINGS_PAGES = "/bo/settings/pages",
}

export const ROUTES_WITH_LAYOUT = [
    ROUTES.DASHBOARD,
    // ROUTES.HOME,
    ROUTES.PARTNERSHIP,
    ROUTES.DOCUMENTS,
    ROUTES.IPFS,
    ROUTES.PLAN,
    ROUTES.BACKUP_RECOVER,
    ROUTES.MY_ACCOUNT,
    ROUTES.SUBSCRIPTION,
    ROUTES.CATEGORIES,
    ROUTES.ADMIN,
    ROUTES.ADMIN_DASHBOARD,
    ROUTES.ADMIN_USERS,
    ROUTES.ADMIN_USERS_TX,
    ROUTES.ADMIN_SETTINGS,
    ROUTES.ADMIN_SETTINGS_ADMIN_LIST,
    ROUTES.ADMIN_SETTINGS_LICENSES,
    ROUTES.ADMIN_SETTINGS_PAGES,
];
