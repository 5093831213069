import { ApolloError, useMutation, useQuery } from "@apollo/client";
import { FC, Key, useContext, useState } from "react";
import { sweetalert } from "../../utils/sweetalert";
import { useIntl } from "react-intl";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { GET_MY_CATEGORIES } from "../../graphql/category/query";
import { GetMyCategories } from "../../graphql/category/types/GetMyCategories";
import { GetMyCategories_myCategories } from "../../graphql/category/types/GetMyCategories";
import { SnackBarContext } from "../../providers/SnackBarProvider/SnackBarProvider";
import { MdAdd } from "react-icons/md";
import { Grid } from "@material-ui/core";
import { Space, Button, Badge } from "antd";
import { Skeleton } from "@material-ui/lab";
import { DeleteCategory } from "../../graphql/category/types/DeleteCategory";
import { DeleteCategoryVariables } from "../../graphql/category/types/DeleteCategory";
import { DELETE_CATEGORY } from "../../graphql/category/mutation";
import { DELETE_SUB_CATEGORY } from "../../graphql/category/mutation";
import { DeleteSubCategory } from "../../graphql/category/types/DeleteSubCategory";
import { DeleteSubCategoryVariables } from "../../graphql/category/types/DeleteSubCategory";
import SearchFilter from "../../components/SearchFilter/SearchFilter";
import NewCategoryModal from "./NewCategoryModal/NewCategoryModal";
import ConfirmDelete from "../../components/ConfirmDelete/ConfirmDelete";
import CustomAntdTable from "../../components/CustomAntdTable/CustomAntdTable";
import TableLoader from "../../components/TableLoader/TableLoader";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomPaper from "../../components/CustomPaper/CustomPaper";
import CustomTitle from "../../components/CustomTitle/CustomTitle";
import PageContainer from "../../components/PageContainer/PageContainer";
import TitleWithBreadcrumb from "../../components/TitleWithBreadcrumb/TitleWithBreadcrumb";
import BulkDelete from "../../components/BulkDelete/BulkDelete";
import useStyles from "./styles";

const Categories: FC<RouteComponentProps> = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { displaySnackBar } = useContext(SnackBarContext);
    const [openCategModal, setOpenCategModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState<
        GetMyCategories_myCategories[]
    >([]);
    const [search, setSearch] = useState<string>("");
    const [categAction, setCategAction] = useState<string>("");
    const [selectedCateg, setSelectedCateg] =
        useState<GetMyCategories_myCategories | null>(null);

    const { data, loading } = useQuery<GetMyCategories>(GET_MY_CATEGORIES, {
        onError: (err) => onError(err),
    });

    const [deleteCategory, { loading: deleteCategoryLoader }] = useMutation<
        DeleteCategory,
        DeleteCategoryVariables
    >(DELETE_CATEGORY, {
        onCompleted: async () => onDeleteCategSuccess(),
        onError: (error) => onError(error),
        refetchQueries: [{ query: GET_MY_CATEGORIES }],
    });

    const [deleteSubCategory, { loading: deleteSubCategoryLoader }] =
        useMutation<DeleteSubCategory, DeleteSubCategoryVariables>(
            DELETE_SUB_CATEGORY,
            {
                onCompleted: async () => onDeleteCategSuccess(),
                onError: (error) => onError(error),
                refetchQueries: [{ query: GET_MY_CATEGORIES }],
            }
        );

    const onDeleteCategSuccess = () => {
        setOpenDeleteModal(false);
        sweetalert(
            "success",
            "Deleted",
            `${
                selectedRows?.length
                    ? `${selectedRows?.length} records were`
                    : `${selectedCateg?.name} was`
            } successfully deleted!`
        );
    };

    const onError = (error: ApolloError) => {
        displaySnackBar({
            message: formatMessage({ id: error.message || "error.unknown" }),
            type: "error",
        });
    };
    const myCategories = data?.myCategories || [];

    const handleCategory = (
        open: boolean,
        type?: string,
        category?: GetMyCategories_myCategories
    ) => {
        setOpenCategModal(open);
        setCategAction(type || "");
        setSelectedCateg(category || null);
    };

    const handleClickDelete = (category: GetMyCategories_myCategories) => {
        setOpenDeleteModal(true);
        setSelectedCateg(category);
    };

    const confirmDeleteCategory = () => {
        const _delete =
            selectedCateg && selectedCateg?.__typename === "Category"
                ? deleteCategory
                : deleteSubCategory;
        _delete({
            variables: { id: Number(selectedCateg?.id) },
        });
    };

    const bulkConfirmDelete = async () => {
        const reqArr = selectedRows.map((categ, idx) => {
            const _delete =
                categ && categ?.__typename === "Category"
                    ? deleteCategory
                    : deleteSubCategory;
            _delete({ variables: { id: Number(categ?.id) } }).then(() => {
                if (selectedRows?.length === idx + 1) {
                    setSelectedRows([]);
                }
            });
            return categ;
        });

        await Promise.all(reqArr);
    };

    const onSelectChange = (
        _: Key[],
        selectedRows: GetMyCategories_myCategories[]
    ) => {
        setSelectedRows(selectedRows);
    };

    const filteredCategories = myCategories?.length
        ? myCategories.filter((categ) => {
              return categ.name.toLowerCase().startsWith(search?.toLowerCase());
          })
        : null;

    return (
        <PageContainer title="Categories">
            <Grid container justifyContent="space-between">
                <Grid item xs={10} sm={9}>
                    <TitleWithBreadcrumb
                        title="menu.categories"
                        routes={[
                            {
                                path: ROUTES.CATEGORIES,
                                title: "menu.categories",
                            },
                        ]}
                        hideDivider
                    />
                </Grid>
                <Grid
                    item
                    xs={2}
                    sm={3}
                    className="d-flex align-items-start justify-content-end"
                >
                    {loading ? (
                        <Skeleton
                            width={200}
                            height={64}
                            className={classes.btnLoader}
                        />
                    ) : (
                        <CustomButton
                            text={formatMessage({
                                id: "categories.add.category",
                            })}
                            onClick={() => handleCategory(true, "add")}
                            hideTextOnMobile
                            style={{
                                marginTop: "10px",
                                borderRadius: 6,
                                color: "#fff",
                                background: "#01579B",
                                paddingLeft: "2em",
                                paddingRight: "2em",
                            }}
                            icon={<MdAdd fontSize={18} />}
                        />
                    )}
                </Grid>
            </Grid>
            <hr />

            {loading ? (
                <TableLoader />
            ) : (
                <CustomPaper>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        className="mt-2"
                    >
                        <Grid item>
                            <Space>
                                <CustomTitle title="categories.all" />
                                <Badge
                                    count={`${myCategories?.length || 0}`}
                                    overflowCount={10000}
                                    style={{
                                        marginTop: "-1em",
                                        backgroundColor: "#0B9F6F", //0B9F6F 35A553
                                    }}
                                />
                            </Space>
                        </Grid>
                        <Grid item className="d-flex justify-content-end">
                            <SearchFilter
                                value={search}
                                setValue={setSearch}
                                results={filteredCategories?.length}
                            />
                        </Grid>
                    </Grid>

                    <div className="mb-1">
                        <BulkDelete
                            selected={selectedRows?.length}
                            list={filteredCategories?.length}
                            divClass="p-2"
                            handleDelete={() => setOpenDeleteModal(true)}
                        />
                    </div>

                    <CustomAntdTable
                        data={filteredCategories}
                        rowKey="id"
                        childrenColumnName="subCategories"
                        expandable
                        pagination
                        checkbox
                        selectedKey="id"
                        selectedRow={selectedCateg}
                        rowSelection={{
                            selectedRowKeys: selectedRows?.map(
                                (item) => item?.id
                            ),
                            onChange: onSelectChange,
                        }}
                        columns={[
                            {
                                title: formatMessage({ id: "categories.name" }),
                                dataIndex: "name",
                                key: "name",
                                sorter: (a: any, b: any) =>
                                    a?.name?.localeCompare(b.name),
                            },
                            {
                                title: formatMessage({
                                    id: "categories.actions",
                                }),
                                dataIndex: "actions",
                                key: "actions",
                                width: 260,
                                render: (
                                    _: any,
                                    item: GetMyCategories_myCategories
                                ) => {
                                    return (
                                        <Space>
                                            <Button
                                                size="middle"
                                                type="ghost"
                                                className={classes.subCategBtn}
                                                disabled={
                                                    item?.__typename !==
                                                    "Category"
                                                }
                                                onClick={() =>
                                                    handleCategory(
                                                        true,
                                                        "add",
                                                        item
                                                    )
                                                }
                                            >
                                                {formatMessage({
                                                    id: "categories.add.subcategory",
                                                })}
                                            </Button>
                                            <Button
                                                size="middle"
                                                type="ghost"
                                                className={classes.subCategBtn}
                                                onClick={() =>
                                                    handleCategory(
                                                        true,
                                                        "update",
                                                        item
                                                    )
                                                }
                                            >
                                                {formatMessage({
                                                    id: "common.edit",
                                                })}
                                            </Button>
                                            <Button
                                                size="middle"
                                                type="ghost"
                                                onClick={() =>
                                                    handleClickDelete(item)
                                                }
                                                className={classes.deleteBtn}
                                            >
                                                {formatMessage({
                                                    id: "common.delete",
                                                })}
                                            </Button>
                                        </Space>
                                    );
                                },
                            },
                        ]}
                    />
                </CustomPaper>
            )}
            <NewCategoryModal
                open={openCategModal}
                action={categAction}
                handleCategory={handleCategory}
                category={selectedCateg}
            />
            <ConfirmDelete
                open={openDeleteModal}
                toggleModal={setOpenDeleteModal}
                submit={
                    selectedRows?.length
                        ? bulkConfirmDelete
                        : confirmDeleteCategory
                }
                loading={deleteCategoryLoader || deleteSubCategoryLoader}
                message={`Are you sure you want to delete <b>${
                    selectedRows?.length
                        ? `(${selectedRows?.length}) records`
                        : selectedCateg?.name || ""
                }</b>? This process cannot be undone.`}
                title={selectedRows?.length ? "Confirm Bulk Delete" : ""}
            />
        </PageContainer>
    );
};

export default withRouter(Categories);
