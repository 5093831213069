import { useContext, FC, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { SEND_PARTNERSHIP_REQUEST } from "../../../graphql/partnership/mutation";
import { SendPartnershipRequest } from "../../../graphql/partnership/types/SendPartnershipRequest";
import { SendPartnershipRequestVariables } from "../../../graphql/partnership/types/SendPartnershipRequest";
import { isEmailValid } from "../../../utils/isEmailValid";
import { IS_EMAIL_REGISTRED } from "../../../graphql/user/query";
import { IsEmailRegistred } from "../../../graphql/user/types/IsEmailRegistred";
import { IsEmailRegistredVariables } from "../../../graphql/user/types/IsEmailRegistred";
import { PARTNERSHIP_TAB_ROUTES, PARTNERSHIP_TAB_TITLES } from "../Partnership";
import { ROUTES } from "../../../constants/routes";
import { GET_MY_PARTNERSHIP_REQUESTS } from "../../../graphql/partnership/query";
import { getRequestStatus } from "../../../utils/mapStatus";
import { sweetalert } from "../../../utils/sweetalert";
import useDebounce from "../../../customHooks/useDebounce/useDebounce";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import CustomTextField from "../../../components/CustomTextField/CustomTextField";

interface IProps {
    open: boolean;
    setOpen(open: boolean): void;
    tab?: PARTNERSHIP_TAB_TITLES;
    setSelectedTab(tab: PARTNERSHIP_TAB_TITLES): void;
}

const NewPartnerModal: FC<IProps> = (props) => {
    const { open, setOpen, tab, setSelectedTab } = props;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const history = useHistory();

    const [email, setEmail] = useState<string>("");

    const requiredFieldsError = !email;
    const invalidEmail = !!email && !isEmailValid(email);

    const debouncedEmail = useDebounce(email);

    const { data } = useQuery<IsEmailRegistred, IsEmailRegistredVariables>(
        IS_EMAIL_REGISTRED,
        {
            variables: {
                email: debouncedEmail,
            },
            fetchPolicy: "cache-first",
            skip: requiredFieldsError || invalidEmail,
            onError: (err) => {
                displaySnackBar({
                    message: formatMessage({
                        id: err.message || "error.unknown",
                    }),
                    type: "error",
                });
            },
        }
    );

    const [sendPartnershipRequest, { loading }] = useMutation<
        SendPartnershipRequest,
        SendPartnershipRequestVariables
    >(SEND_PARTNERSHIP_REQUEST, {
        onCompleted: async (data) => {
            // if (data.sendPartnershipRequest) {
            sweetalert(
                "success",
                "Success",
                formatMessage({
                    id: data.sendPartnershipRequest.recipient
                        ? "success.partnership.invitation.sent"
                        : "success.partnership.invitation.sent.via.email",
                })
            );

            if (tab === PARTNERSHIP_TAB_TITLES.PARTNERS) {
                setSelectedTab(PARTNERSHIP_TAB_TITLES.INVITATIONS);
                history.push(
                    `${ROUTES.PARTNERSHIP}/${PARTNERSHIP_TAB_ROUTES.INVITATIONS}`
                );
            }
            handleCloseDialog();
            // }
        },
        onError: (error) => {
            displaySnackBar({
                message: error.message
                    ? error.message
                    : formatMessage({ id: "error.unknown" }),
                type: "error",
            });
        },
        refetchQueries: [
            {
                query: GET_MY_PARTNERSHIP_REQUESTS,
                variables: { status: getRequestStatus("common.pending") },
            },
        ],
    });

    const handleSendPartnershipRequest = () => {
        sendPartnershipRequest({
            variables: {
                input: { targetEmail: email },
            },
        });
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setEmail("");
    };

    const isEmailRegistred = data ? data?.isEmailRegistred : false;

    const emailNotRegistred =
        !requiredFieldsError && !invalidEmail && !isEmailRegistred;

    return (
        <CustomDialog
            loading={loading}
            open={open}
            handleClose={handleCloseDialog}
            title={formatMessage({
                id: "partnership.invitation.new.partner.title",
            })}
            text={formatMessage({
                id: "partnership.invitation.new.partner.subtitle",
            })}
            submitText={formatMessage({
                id: emailNotRegistred
                    ? "common.invite.via.email"
                    : "common.invite",
            })}
            handleSubmit={handleSendPartnershipRequest}
            disableSubmit={requiredFieldsError || invalidEmail || loading}
        >
            <CustomTextField
                label={formatMessage({ id: "common.email" })}
                type="email"
                name="email"
                value={email}
                handlechange={(value) => {
                    setEmail(value);
                }}
                autoFocus
                onKeyDown={(e) => {
                    if (e?.key === "Enter" && email && !invalidEmail) {
                        handleSendPartnershipRequest();
                    }
                }}
                inputRef={(input) => input && input.focus()}
                error={invalidEmail}
                helperText={
                    invalidEmail
                        ? formatMessage({ id: "common.email.invalid" })
                        : undefined
                }
            />
            {/* {emailNotRegistred && (
                <div className={classes.sendRegistrationMessage}>
                    {formatMessage({
                        id: "partnership.invitation.new.partner.not.registred",
                    })}
                </div>
            )} */}
        </CustomDialog>
    );
};

export default NewPartnerModal;
