import { makeStyles } from "@material-ui/core";
import { FC, useContext } from "react";
import { Image } from "react-bootstrap";
import { useIntl } from "react-intl";
import { UserContext } from "../../providers/UserProvider/UserProvider";
import idLogo from "../../images/id_logo.png";

const useStyles = makeStyles(() => ({
    idAccountContainer: {
        display: "flex",
        marginTop: 20,
        alignItems: "center",
        width: "100%",
        justifyContent: "center",
    },
}));

interface Props {
    src?: string | null | undefined;
}

const IdAccount: FC<Props> = () => {
    const classes = useStyles();
    const { formatMessage } = useIntl();
    const { meId } = useContext(UserContext);

    return (
        <div className={classes.idAccountContainer}>
            <Image
                src={idLogo}
                width="60px"
                height="auto"
                style={{ marginRight: 10 }}
            />
            <div>
                <div style={{ fontWeight: "bold" }}>
                    {formatMessage({
                        id: "common.connected.id.account",
                    })}
                </div>
                {meId?.email}
            </div>
        </div>
    );
};

export default IdAccount;
