import { FC, useState } from "react";
import { Link } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { ROUTES } from "../../constants/routes";
import { useIntl } from "react-intl";
import { Button as AntButton, Checkbox } from "antd";
import type { CheckboxChangeEvent } from "antd/es/checkbox";
import config from "../../config/config";
import useStyles from "./styles";
import AuthLayout from "./AuthLayout";

const SignupPage: FC = () => {
    const classes = useStyles();
    const { formatMessage, locale } = useIntl();
    const [agreed, setAgreed] = useState(false);

    const onCheckboxChange = (e: CheckboxChangeEvent) => {
        setAgreed(e.target.checked);
    };

    const signUpId = () => {
        window.location.href = `${
            config?.id?.baseUrl
        }/registration?after_verification_return_to=${
            window?.location?.origin || config?.uiUrl
        }${ROUTES?.SIGNIN}`;
    };

    return (
        <AuthLayout type="signup" title="auth.signup.link" pageTitle="Sign Up">
            <div className="d-flex align-items-center flex-column py-4 wp100">
                <Checkbox className="mt-2" onChange={onCheckboxChange}>
                    <Typography>
                        {formatMessage({
                            id: "auth.signup.terms.confirm.desc",
                        })}{" "}
                        <Link
                            target="_blank"
                            href={config.termsOfServiceUrl.replace(
                                "locale",
                                locale
                            )}
                        >
                            {formatMessage({
                                id: "auth.signup.terms.tos",
                            })}{" "}
                        </Link>
                        and{" "}
                        <Link
                            href={config.privacyPolicyUrl.replace(
                                "locale",
                                locale
                            )}
                            target="_blank"
                        >
                            {formatMessage({
                                id: "auth.signup.terms.privacy.policy",
                            })}
                        </Link>
                    </Typography>
                </Checkbox>
            </div>
            <AntButton
                size="large"
                type="primary"
                disabled={!agreed}
                className={classes.submitBtn}
                onClick={signUpId}
            >
                {formatMessage({
                    id: "auth.signup.terms.continue",
                })}
            </AntButton>
        </AuthLayout>
    );
};

export default SignupPage;
