import { useMutation, useQuery } from "@apollo/client";
import { UploadChangeParam, UploadFile } from "antd/lib/upload";
import { FC, useContext, useState } from "react";
import { useIntl } from "react-intl";
import { TRANSFER_DOCUMENT } from "../../../graphql/document/mutation";
import { TransferDocument } from "../../../graphql/document/types/TransferDocument";
import { TransferDocumentVariables } from "../../../graphql/document/types/TransferDocument";
import { SnackBarContext } from "../../../providers/SnackBarProvider/SnackBarProvider";
import { createIpfsFile } from "../../../utils/ipfs";
import { UserContext } from "../../../providers/UserProvider/UserProvider";
import { DOCUMENT_TAB_TITLES } from "../Document";
import { Radio, RadioGroup, RadioProps, Grid } from "@material-ui/core";
import { FormControlLabel, withStyles, Tooltip } from "@material-ui/core";
import { generateArmoredFileName, getFileSize } from "../../../utils/document";
import { generatePassphraseFileName } from "../../../utils/document";
import { MyPartners_myPartners } from "../../../graphql/partnership/types/MyPartners";
import { ExpiresIn, IPFSType } from "../../../types/graphql-global-types";
import { TransferSecurityLevel } from "../../../types/graphql-global-types";
import { ethers } from "ethers";
import { saveAs } from "file-saver";
import { GetMyCategories } from "../../../graphql/category/types/GetMyCategories";
import { GetMyCategories_myCategories } from "../../../graphql/category/types/GetMyCategories";
import { GET_MY_CATEGORIES } from "../../../graphql/category/query";
import { Space, Alert, Select, Typography } from "antd";
import { GET_CURRENT_STATE } from "../../../graphql/license/query";
import { GetMyPlan } from "../../../graphql/user/types/GetMyPlan";
import { GET_MY_PLAN } from "../../../graphql/user/query";
import { sweetalert } from "../../../utils/sweetalert";
import { DATETIME_FORMAT } from "../../../constants/datetime";
import { zipBlobs } from "../../../utils/document";
import * as openpgp from "openpgp";
import CustomDialog from "../../../components/CustomDialog/CustomDialog";
import COLORS from "../../../constants/colors";
import CustomAutocompletePartners from "../../../components/CustomAutocompletePartners/CustomAutocompletePartners";
import FileUploadDragger from "../../../components/FileUploadDragger/FileUploadDragger";
import useStyles from "../styles";
import FormLabel from "../../../components/FormLabel/FormLabel";
import moment from "moment";
import useBreakpoint from "../../../customHooks/useBreakpoint/useBreakpoint";
import fileSize from "file-size";
import { MAX_FILE_SIZE } from "../../../constants/fileSize";
import { ethEncrypt } from "../../../services/tracerkey";

const { Option } = Select;

enum DocumentUploading {
    UPLOADING = "UPLOADING",
    ENCRYPTING = "ENCRYPTING",
    SAVING = "SAVING",
    DONE = "",
}

const CustomRadio = withStyles({
    root: { color: COLORS.primaryDark },
    checked: {},
})((props: RadioProps) => <Radio color="default" {...props} />);

interface IProps {
    open: boolean;
    setOpen(open: boolean): void;
    setSelectedTab?(tab: DOCUMENT_TAB_TITLES): void;
    refetch: any;
}

const DocumentTransferModal: FC<IProps> = (props) => {
    const classes = useStyles();
    const breakpoint = useBreakpoint();
    const { open, setOpen, setSelectedTab, refetch } = props;
    const { displaySnackBar } = useContext(SnackBarContext);
    const { formatMessage } = useIntl();
    const [partner, setPartner] = useState<MyPartners_myPartners | undefined>(
        undefined
    );
    const [noSelectedPartner, setNoPartner] = useState(false);
    const [exceedsMaxFileSize, setExceedsMaxFileSize] = useState(false);
    const [expiresIn, setExpiresIn] = useState<ExpiresIn | undefined>(
        ExpiresIn.D001
    );
    const [uploadLoading, setLoading] = useState<DocumentUploading>(
        DocumentUploading.DONE
    );
    const [file, setFile] = useState<File | undefined>(undefined);
    const { me } = useContext(UserContext);
    const [categoryId, setCategoryId] = useState<any>(undefined);
    const [category, setCategory] = useState<
        GetMyCategories_myCategories | undefined
    >(undefined);
    const [subCategoryId, setSubCategoryId] = useState<any>(undefined);
    const [ipfsType, setIpfsType] = useState<IPFSType | string | null>(
        IPFSType.PUBLIC
    );

    const onError = (err: any) => {
        displaySnackBar({
            message: err.message || formatMessage({ id: "error.unknown" }),
            type: "error",
        });
    };

    const { data: plan } = useQuery<GetMyPlan>(GET_MY_PLAN, {
        onError: (err) => onError(err),
    });

    const { data: categoryData } = useQuery<GetMyCategories>(
        GET_MY_CATEGORIES,
        { onError: (err) => onError(err) }
    );

    const { data: currentStateData } = useQuery(GET_CURRENT_STATE, {
        onCompleted: (data) => {
            const { pubFileCount, prvFileCount, license } =
                data?.currentState || {};
            const { pubFileCountMonthly, prvFileCountMonthly } = license || {};
            const isMaxPubStorage = pubFileCount === pubFileCountMonthly;
            const isMaxPrivStorage = prvFileCount === prvFileCountMonthly;
            if (
                (isMaxPubStorage && isMaxPrivStorage) ||
                (pubFileCountMonthly === 0 && prvFileCountMonthly === 0)
            ) {
                setIpfsType(null);
                // setTransferMode(TransferMode.DOWNLOAD);
            }
        },
        onError: (err) => onError(err),
    });

    const { currentState } = currentStateData || {};
    const { license } = currentState || {};

    const selectedIpfsTypeMaxLimit =
        license?.[
            ipfsType === IPFSType.PRIVATE
                ? "prvFilesizeMonthly"
                : "pubFilesizeMonthly"
        ] * 1000000;
    const selectedIpfsTypeUsedStorage =
        currentState?.[
            ipfsType === IPFSType.PRIVATE ? "prvFilesize" : "pubFilesize"
        ];

    const storageAfterUpload = file
        ? selectedIpfsTypeMaxLimit - selectedIpfsTypeUsedStorage - file?.size
        : 0;

    const myCategories = categoryData?.myCategories || [];

    const handleChangeIpfsType = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        setIpfsType((event.target as HTMLInputElement).value as IPFSType);
    };

    const [saveDocumentTransfer, { loading: loadingSave }] = useMutation<
        TransferDocument,
        TransferDocumentVariables
    >(TRANSFER_DOCUMENT, {
        onCompleted: async (data) => {
            if (data.transferDocument) {
                sweetalert(
                    "success",
                    "Success",
                    formatMessage({
                        id: `success.document.${
                            !noSelectedPartner ? "transfered" : "store.personal"
                        }`,
                    })
                );

                setLoading(DocumentUploading.DONE);
                handleCloseDialog();
                await refetch();
                if (setSelectedTab) {
                    setSelectedTab(DOCUMENT_TAB_TITLES.LIST);
                }
            }
        },
        onError: (error) => {
            setLoading(DocumentUploading.DONE);
            onError(error);
        },
    });

    const onChangeFile = (info: UploadChangeParam<UploadFile<any>>) => {
        const f = info?.file?.originFileObj;
        setFile(f);

        if (f) {
            const x = fileSize(f?.size || 0).to("MB", "jedec");
            setExceedsMaxFileSize(Number(x) > MAX_FILE_SIZE);
        }
    };

    const onRemoveFile = () => {
        setFile(undefined);
    };

    const handleEncryptDocument = async (passphrase: string, f: File) => {
        try {
            setLoading(DocumentUploading.ENCRYPTING);
            const arrayBuffer = await f.arrayBuffer();
            const binaryFile = new Uint8Array(arrayBuffer);

            // file
            const message = await openpgp.createMessage({
                binary: binaryFile,
                filename: f.name,
            });

            // Encrypt file with passphrase
            const armoredString = await openpgp.encrypt({
                message,
                passwords: [passphrase],
            });

            const armoredFileName = generateArmoredFileName(f.name);

            const armored = createIpfsFile(
                armoredString as string,
                armoredFileName,
                "text/plain"
            );

            return {
                armored,
                armoredFileName,
            };
        } catch (error) {
            setLoading(DocumentUploading.DONE);
            onError(error);
        }
    };

    const handleEncryptPassphrase = async (
        passphrase: string,
        publicKey: string,
        pqPublicKey: string,
        fileName: string,
        wallet: string
    ) => {
        try {
            setLoading(DocumentUploading.ENCRYPTING);

            const armoredFileName = generatePassphraseFileName(
                fileName,
                wallet
            );

            const passphraseData = {
                armored: passphrase,
                fileName,
                armoredFileName,
            };

            const passphrasePayload = JSON.stringify(passphraseData);

            const encryptedPassphrase = await ethEncrypt(
                passphrasePayload,
                publicKey,
                pqPublicKey
            );

            console.log("encryptedPassphrase", encryptedPassphrase);

            const armored = createIpfsFile(
                JSON.stringify(encryptedPassphrase),
                armoredFileName,
                "text/plain"
            );

            return {
                armored,
                armoredFileName,
            };
        } catch (error) {
            setLoading(DocumentUploading.DONE);
            onError(error);
        }
    };

    const handleEncryptAllDocuments = async () => {
        if (!file) {
            // No file error
            return;
        }

        if ((!partner || !partner.partner) && !noSelectedPartner) {
            // No user error
            return;
        }

        if (!me) {
            // User not connected error
            return;
        }

        if (
            (!partner ||
                !partner.partner.activated ||
                !partner.partner.wallet.encryptionPublicKey) &&
            !noSelectedPartner
        ) {
            // No user erro
            return;
        }

        if (!me.activated || !me.wallet.encryptionPublicKey) {
            // No encryptionPublicKey
            displaySnackBar({
                type: "error",
                message: formatMessage({
                    id: "common.partner.status.locked.desc",
                }),
            });
            setLoading(DocumentUploading.DONE);
            return;
        }

        setLoading(DocumentUploading.ENCRYPTING);
        // random passphrase
        const passphrase = ethers.utils.hexlify(ethers.utils.randomBytes(160));
        const pgpFile = await handleEncryptDocument(passphrase, file);
        if (!pgpFile) {
            // No file error
            return;
        }

        const myEncryptedPassphrase = await handleEncryptPassphrase(
            passphrase,
            me.wallet.encryptionPublicKey,
            me.wallet.pqEncryptionPublicKey || "",
            file.name,
            me.wallet.address
        );

        const partnerEncryptedPassphrase = !noSelectedPartner
            ? await handleEncryptPassphrase(
                  passphrase,
                  partner?.partner?.wallet?.encryptionPublicKey || "",
                  partner?.partner?.wallet?.pqEncryptionPublicKey || "",
                  file.name,
                  partner?.partner?.wallet?.address || ""
              )
            : null;

        if (
            !pgpFile ||
            !myEncryptedPassphrase ||
            (!partnerEncryptedPassphrase && !noSelectedPartner)
        ) {
            displaySnackBar({
                message: formatMessage({
                    id: "error.document.encryption",
                }),
                type: "error",
            });
            setLoading(DocumentUploading.DONE);
            return;
        }

        return {
            pgpFile,
            myEncryptedPassphrase,
            partnerEncryptedPassphrase,
        };
    };

    const handleTransferDocument = async () => {
        if ((!partner || !partner.partner) && !noSelectedPartner) {
            // No user error
            return;
        }

        const documents = await handleEncryptAllDocuments();
        if (!documents) {
            displaySnackBar({
                message: formatMessage({
                    id: "error.document.encryption",
                }),
                type: "error",
            });
            setLoading(DocumentUploading.DONE);
            return;
        }

        const { pgpFile, myEncryptedPassphrase, partnerEncryptedPassphrase } =
            documents;

        setLoading(DocumentUploading.SAVING);
        saveDocumentTransfer({
            variables: {
                input: {
                    document: pgpFile.armored,
                    recipientPassphrase: !noSelectedPartner
                        ? partnerEncryptedPassphrase?.armored
                        : null,
                    senderPassphrase: myEncryptedPassphrase?.armored,
                    // ipfsType: ipfsType
                    //     ? ipfsType === IPFSType.PRIVATE
                    //         ? IPFSType.PRIVATE
                    //         : IPFSType.PUBLIC
                    //         ? IPFSType.PUBLIC
                    //         : IPFSType.PUBLIC
                    //     : IPFSType.PUBLIC,
                    ipfsType: IPFSType.PRIVATE,
                    userId: !noSelectedPartner ? partner?.partner.id : null,
                    securityLevel: TransferSecurityLevel.CONFIDENTIAL,
                    fileName: file?.name,
                    fileSize: file?.size,
                    categoryIds: categoryId ? [Number(categoryId)] : undefined,
                    subCategoryIds: subCategoryId
                        ? [Number(subCategoryId)]
                        : undefined,
                    expiresIn,
                },
            },
        });
    };

    const handleDownloadEncryptedDocument = async () => {
        const documents = await handleEncryptAllDocuments();

        console.log("documents", documents);
        if (!documents) {
            displaySnackBar({
                message: formatMessage({ id: "error.document.encryption" }),
                type: "error",
            });
            setLoading(DocumentUploading.DONE);
            return;
        }

        const { pgpFile, myEncryptedPassphrase, partnerEncryptedPassphrase } =
            documents;

        const pgpFileBuffer = await pgpFile.armored.arrayBuffer();
        const pgpFileBlob = new Blob([pgpFileBuffer]);
        const myEncryptedPassphraseBuffer =
            await myEncryptedPassphrase?.armored.arrayBuffer();
        const myEncryptedPassphraseBlob = myEncryptedPassphraseBuffer
            ? new Blob([myEncryptedPassphraseBuffer])
            : undefined;

        const partnerEncryptedPassphraseBuffer =
            await partnerEncryptedPassphrase?.armored.arrayBuffer();
        const partnerEncryptedPassphraseBlob = partnerEncryptedPassphraseBuffer
            ? new Blob([partnerEncryptedPassphraseBuffer])
            : undefined;

        const blobs = [
            { blob: pgpFileBlob, fileName: pgpFile.armoredFileName },
            {
                blob: myEncryptedPassphraseBlob,
                fileName: myEncryptedPassphrase?.armoredFileName,
            },
        ];
        if (!noSelectedPartner) {
            blobs.push({
                blob: partnerEncryptedPassphraseBlob,
                fileName: partnerEncryptedPassphrase?.armoredFileName || "",
            });
        }

        try {
            zipBlobs(blobs).then((blob: any) => {
                saveAs(blob, `${file?.name || ""}.tdPack.zip`);
            });
        } catch (error) {
            console.log("Download error : ", error);
            setLoading(DocumentUploading.DONE);
        }

        setLoading(DocumentUploading.DONE);
        handleCloseDialog();
    };

    const handleChangeCategory = (categoryId: number) => {
        setCategoryId(categoryId);
        const cat = myCategories.find((c) => Number(c.id) === categoryId);
        setSubCategoryId(
            cat?.subCategories?.length ? cat?.subCategories?.[0]?.id : undefined
        );
        setCategory(cat);
    };

    const handleChangeSubCategory = (event: number) => {
        const subCatId = Number(event);
        setSubCategoryId(subCatId);
    };

    const onPartnerChange = (
        partner: MyPartners_myPartners | undefined | string
    ) => {
        if (typeof partner === "string") {
            setNoPartner(true);
        } else {
            setPartner(partner);
            setNoPartner(false);
        }
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setFile(undefined);
        setPartner(undefined);
        setCategory(undefined);
        setCategoryId(undefined);
        setSubCategoryId(undefined);
        setIpfsType(IPFSType.PUBLIC);
        setNoPartner(false);
        setExpiresIn(ExpiresIn.D001);
        setExceedsMaxFileSize(false);
    };

    return (
        <CustomDialog
            loading={uploadLoading !== DocumentUploading.DONE || loadingSave}
            open={open}
            handleClose={handleCloseDialog}
            title={formatMessage({ id: "document.add.new.modal.title" })}
            text={formatMessage({ id: "document.encryption.subtitle" })}
            submitText={formatMessage({
                id:
                    noSelectedPartner && ipfsType === "NO"
                        ? "document.download.encrypted.file"
                        : noSelectedPartner && ipfsType !== "NO"
                        ? "document.store.encrypted.file"
                        : !noSelectedPartner && partner && ipfsType !== "NO"
                        ? "document.store.share.encrypted.file"
                        : !noSelectedPartner && ipfsType === "NO"
                        ? "document.download.shareable.file"
                        : "common.submit",
            })}
            handleSubmit={
                ipfsType === "NO"
                    ? handleDownloadEncryptedDocument
                    : handleTransferDocument
            }
            disableSubmit={
                !file ||
                (!partner && !noSelectedPartner) ||
                storageAfterUpload < 0 ||
                exceedsMaxFileSize
            }
            fullWidth
            maxWidth="md"
        >
            <div className={classes.root}>
                <FileUploadDragger
                    onChange={onChangeFile}
                    noMargin
                    gridSize={12}
                    showIcon
                    background="#F6F8FE"
                    hideTitle
                    showUploadList={false}
                    file={!exceedsMaxFileSize ? file : undefined}
                    removeFile={onRemoveFile}
                />
                {exceedsMaxFileSize && (
                    <Alert
                        message={
                            <span style={{ fontWeight: 500 }}>{`${
                                file?.name || ""
                            } file size exceeds maximum file size limit. Maximum allowed file size is ${MAX_FILE_SIZE} MB.`}</span>
                        }
                        type="error"
                        showIcon
                        className={classes.alertError}
                    />
                )}
                <CustomAutocompletePartners
                    partner={partner}
                    label="common.send.to"
                    onPartnerChange={onPartnerChange}
                    noSelectedPartner={noSelectedPartner}
                />

                {(partner?.partner?.activated || noSelectedPartner) && file && (
                    <Typography className="font-weight-bold mt-4">
                        Storage
                    </Typography>
                )}
                <Grid container className="mt-2" spacing={2}>
                    <Grid item xs={12} sm={12}>
                        {(partner?.partner?.activated || noSelectedPartner) &&
                            file && (
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container>
                                            <Grid item xs={12} sm={3}>
                                                <div className="mt-2">
                                                    <FormLabel
                                                        required
                                                        label="document.transfer.type"
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} sm={9}>
                                                <RadioGroup
                                                    onChange={
                                                        handleChangeIpfsType
                                                    }
                                                    aria-label="ipfs-type"
                                                    name="ipfs-type"
                                                    value={ipfsType}
                                                    style={{
                                                        marginLeft:
                                                            breakpoint !== "xs"
                                                                ? "-2em"
                                                                : "0",
                                                    }}
                                                >
                                                    <Space direction="horizontal">
                                                        <Tooltip
                                                            arrow
                                                            title={formatMessage(
                                                                {
                                                                    id: `document.${
                                                                        !plan?.myPlan
                                                                            ? "not.available.free.license"
                                                                            : "no.storage"
                                                                    }`,
                                                                }
                                                            )}
                                                        >
                                                            <FormControlLabel
                                                                value={"NO"}
                                                                disabled={
                                                                    !plan?.myPlan &&
                                                                    !noSelectedPartner
                                                                }
                                                                control={
                                                                    <CustomRadio size="small" />
                                                                }
                                                                label={
                                                                    <span className="font13">
                                                                        {formatMessage(
                                                                            {
                                                                                id: "document.no.storage",
                                                                            }
                                                                        )}
                                                                    </span>
                                                                }
                                                            />
                                                        </Tooltip>
                                                        <FormControlLabel
                                                            value={
                                                                IPFSType.PUBLIC
                                                            }
                                                            control={
                                                                <CustomRadio
                                                                    size="small"
                                                                    disabled={
                                                                        currentState?.pubFileCount ===
                                                                        license?.pubFileCountMonthly
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <span className="font13">
                                                                    {formatMessage(
                                                                        {
                                                                            id: "document.transfer.type.private.ipfs",
                                                                        }
                                                                    )}
                                                                </span>
                                                            }
                                                        />
                                                    </Space>
                                                    {(license?.pubFileCountMonthly ===
                                                        0 ||
                                                        currentState?.pubFileCount ===
                                                            license?.pubFileCountMonthly) && (
                                                        <Alert
                                                            message={formatMessage(
                                                                {
                                                                    id:
                                                                        license?.pubFileCountMonthly ===
                                                                        0
                                                                            ? "document.upgrade.license"
                                                                            : "document.public.storage.max.limit",
                                                                }
                                                            )}
                                                            type="warning"
                                                            showIcon
                                                            className={
                                                                classes.alertWarning
                                                            }
                                                        />
                                                    )}
                                                    {(license?.prvFileCountMonthly ===
                                                        0 ||
                                                        currentState?.prvFileCount ===
                                                            license?.prvFileCountMonthly) && (
                                                        <Alert
                                                            message={formatMessage(
                                                                {
                                                                    id:
                                                                        license?.prvFileCountMonthly ===
                                                                        0
                                                                            ? "document.upgrade.license"
                                                                            : "document.private.storage.max.limit",
                                                                }
                                                            )}
                                                            type="warning"
                                                            showIcon
                                                            className={
                                                                classes.alertWarning
                                                            }
                                                        />
                                                    )}
                                                </RadioGroup>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        {ipfsType !== "NO" && (
                                            <Typography
                                                style={{ marginTop: "0.5em" }}
                                            >
                                                {formatMessage({
                                                    id: "document.storage.after.upload",
                                                })}
                                                :{" "}
                                                <b
                                                    style={{
                                                        color:
                                                            storageAfterUpload <
                                                            0
                                                                ? "#D64D4B"
                                                                : "",
                                                    }}
                                                >
                                                    {getFileSize(
                                                        storageAfterUpload
                                                    )}
                                                </b>
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            )}
                    </Grid>

                    {(noSelectedPartner || partner?.partner?.activated) &&
                        (ipfsType === IPFSType.PRIVATE ||
                            ipfsType === IPFSType.PUBLIC) &&
                        file && (
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                style={{ marginTop: "-0.5em" }}
                            >
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Grid container>
                                            <Grid item xs={12} sm={2}>
                                                <FormLabel
                                                    required
                                                    label="document.duration"
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={10}>
                                                <Select
                                                    value={expiresIn}
                                                    onChange={(e) =>
                                                        setExpiresIn(e)
                                                    }
                                                    dropdownStyle={{
                                                        zIndex: 2000,
                                                    }}
                                                    allowClear
                                                    showSearch
                                                    optionFilterProp="children"
                                                    className="antdSelect"
                                                >
                                                    <Option
                                                        value={undefined}
                                                        disabled={!plan?.myPlan}
                                                    >
                                                        Unlimited{" "}
                                                        {!plan?.myPlan
                                                            ? "(Not available for free license)"
                                                            : ""}
                                                    </Option>
                                                    {Object.values(
                                                        ExpiresIn
                                                    ).map((item, idx) => (
                                                        <Option
                                                            key={idx}
                                                            value={item}
                                                        >
                                                            {formatMessage({
                                                                id: `document.${item.toLowerCase()}`,
                                                            })}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography>
                                            {formatMessage({
                                                id: `document.${
                                                    !expiresIn
                                                        ? "permanent.storage"
                                                        : "auto.remove.activated"
                                                }`,
                                            })}{" "}
                                            <b>
                                                {expiresIn
                                                    ? moment()
                                                          .add(
                                                              expiresIn.slice(
                                                                  2,
                                                                  4
                                                              ),
                                                              "day"
                                                          )
                                                          .format(
                                                              DATETIME_FORMAT
                                                          )
                                                    : ""}
                                            </b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                </Grid>
                <hr className="mt-3" />

                {(noSelectedPartner || partner?.partner?.activated) &&
                    (ipfsType === IPFSType.PRIVATE ||
                        ipfsType === IPFSType.PUBLIC) &&
                    file && (
                        <Grid
                            container
                            spacing={2}
                            className={classes.optional}
                        >
                            <Grid item xs={12} sm={6}>
                                <FormLabel label="categories.category" />
                                <Select
                                    value={
                                        categoryId ? Number(categoryId) : null
                                    }
                                    onChange={(e) => handleChangeCategory(e)}
                                    dropdownStyle={{ zIndex: 2000 }}
                                    allowClear
                                    showSearch
                                    optionFilterProp="children"
                                    className="antdSelect"
                                >
                                    {myCategories?.length
                                        ? myCategories.map((item, idx) => (
                                              <Option
                                                  value={Number(item?.id)}
                                                  key={idx}
                                              >
                                                  {item?.name}
                                              </Option>
                                          ))
                                        : ""}
                                </Select>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormLabel label="categories.subcategory" />

                                <Select
                                    dropdownStyle={{ zIndex: 2000 }}
                                    className="antdSelect"
                                    allowClear
                                    optionFilterProp="children"
                                    value={
                                        subCategoryId
                                            ? Number(subCategoryId)
                                            : null
                                    }
                                    onChange={(e) => handleChangeSubCategory(e)}
                                >
                                    {category?.subCategories?.length
                                        ? category?.subCategories?.map(
                                              (item: any, idx: number) => (
                                                  <Option
                                                      value={Number(item?.id)}
                                                      key={idx}
                                                  >
                                                      {item?.name}
                                                  </Option>
                                              )
                                          )
                                        : ""}
                                </Select>
                            </Grid>
                        </Grid>
                    )}
            </div>
        </CustomDialog>
    );
};

export default DocumentTransferModal;
