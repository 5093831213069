const config = {
    uiUrl: process.env.REACT_APP_BASE_URL || "http://localhost:3000",
    serverUrl: process.env.REACT_APP_SERVER_URL || "http://localhost:4000/",
    backendUrl: process.env.REACT_APP_BACKEND_URL || "http://localhost:4000",
    wsUrl: process.env.REACT_APP_WS_URL || "ws://localhost:4000/",
    appName: process.env.REACT_APP_NAME || "TracerDoc",
    appDescription:
        process.env.REACT_APP_DESCRIPTION ||
        "Your web 3.0 document storage & transfer platform",
    ipfsPinningService:
        process.env.REACT_APP_PINNING_SERVICE ||
        "http://localhost:5001/api/v0/add",
    ipfsGateway: process.env.REACT_APP_IPFS_GATEWAY || "http://localhost:8080",
    // ipfsPublicGateway: throwIfUndefined("REACT_APP_IFPS_PUBLIC_GATEWAY"),
    // ipfsPublicPinningService: throwIfUndefined(
    //     "REACT_APP_PUBLIC_PINNING_SERVICE"
    // ),
    backupEndpoint:
        process.env.REACT_APP_BACKUP_ENDPOINT || "http://localhost:4000/backup",
    keycloak: {
        baseUrl:
            process.env.REACT_APP_KEYCLOAK_BASE_URL ||
            "https://devid.tracerdoc.com/",
        realmName: process.env.REACT_APP_KEYCLOAK_REALM_NAME || "tracerdoc",
        clientId:
            process.env.REACT_APP_KEYCLOAK_CLIENT_ID ||
            "app-node-standard-flow",
    },
    tracerkey: {
        extensionId: process.env.REACT_APP_TRACERKEY_EXTENSION_ID,
    },
    stripe: {
        publishableKey: process.env.REACT_APP_STRIPE_PUB_KEY,
        personalPricingTableId:
            process.env.REACT_APP_STRIPE_PERSONAL_PRICING_TABLE_ID,
        businessPricingTableId:
            process.env.REACT_APP_STRIPE_BUSINESS_PRICING_TABLE_ID,
    },
    termsOfServiceUrl:
        process.env.REACT_APP_TERMS_OF_SERVICE ||
        "https://wiki.tracerdoc.com/locale/tos",
    privacyPolicyUrl:
        process.env.REACT_APP_PRIVACY_POLICY ||
        "https://wiki.tracerdoc.com/locale/privacypolicy",
    tracerkeyInstallationUrl:
        process.env.REACT_APP_TRACERKEY_INSTALL ||
        "https://wiki.tracerdoc.com/en/tkinstall",
    id: {
        baseUrl: process.env.REACT_APP_ID_BASE_URL,
        clientId: process.env.REACT_APP_CLIENT_ID,
        redirectUri: process.env.REACT_APP_ID_REDIRECT_URI,
        whoAmIUrl: process.env.REACT_APP_ID_WHOAMI_URL,
        hydraUrl: process.env.REACT_APP_HYDRA_BASE_URL,
        walletUrl: process.env.REACT_APP_ID_WALLET_BASE_URL,
    },
};

export default config;
