import React, { FC } from "react";
import axios from "axios";
import { useQuery } from "@apollo/client";
import { useState, createContext, useContext, useEffect } from "react";
import { useIntl } from "react-intl";
import { LOCAL_STORAGE_KEYS } from "../../constants/localStorage";
import { GET_ME } from "../../graphql/user/query";
import { GetMe, GetMe_me } from "../../graphql/user/types/GetMe";
import { tracerkeyCheckExtension } from "../../services/tracerkey";
import { tracerkeyConnect } from "../../services/tracerkey";
import { AuthContext } from "../AuthProvider/AuthProvider";
import { SnackBarContext } from "../SnackBarProvider/SnackBarProvider";
import { AuthContext as Oauth2Context } from "react-oauth2-code-pkce";
import { DEVID } from "../../constants/devIdEndpoints";
import config from "../../config/config";

type IDevIdProfile = {
    email: string | null | undefined;
    email_verified: boolean;
    family_name: string | null | undefined;
    given_name: string | null | undefined;
    name: string | null | undefined;
    preferred_username: string | null | undefined;
    sub: string | null | undefined;
};

type IUserContext = {
    me: GetMe_me | null;
    meId: IDevIdProfile | null | undefined;
};

export const UserContext = createContext<IUserContext>({
    me: null,
    meId: undefined,
});

const UserProvider: FC<{
    children: React.ReactNode;
}> = ({ children }) => {
    const [me, setMe] = useState<GetMe_me | null>(null);
    const { displaySnackBar } = useContext(SnackBarContext);
    const { logout } = useContext(AuthContext);
    const { formatMessage } = useIntl();
    const [identity, setIdentity] = useState<any>(null);
    const { token: accessToken } = useContext(Oauth2Context);

    useEffect(() => {
        console.log(accessToken);
        if (accessToken) {
            getUserInfo(accessToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken]);

    const getUserInfo = async (access_token: string) => {
        if (access_token) {
            await axios
                // .get(config?.id?.userInfoUrl as string, {
                .get(`${config?.id?.hydraUrl}${DEVID.USERINFO}` as string, {
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        "Content-Type": "application/json",
                    },
                })
                .then((res) => {
                    if (res?.data?.sub) {
                        axios
                            .get(config?.id?.whoAmIUrl as string, {
                                headers: {
                                    Authorization: `Bearer ${access_token}`,
                                    "Content-Type": "application/json",
                                },
                            })
                            .then((response) => {
                                console.log("WHOAMI RESPONSE", response);
                                const identity = {
                                    ...response?.data,
                                    id: res?.data?.sub,
                                };

                                setIdentity(identity);
                            })
                            .catch((err) => {
                                setIdentity(null);
                            });
                    }
                });
        }
    };

    useQuery<GetMe>(GET_ME, {
        onCompleted: async (data) => {
            if (data && data.me) {
                // console.log(data);
                const tracerkeyInstalled = await tracerkeyCheckExtension();

                if (!tracerkeyInstalled) {
                    displaySnackBar({
                        message: formatMessage({
                            id: "error.not.installed.tracerkey",
                        }),
                        type: "error",
                    });
                    return;
                }

                const { address } = await tracerkeyConnect();

                if (!address) {
                    setMe(null);
                    logout({ error: "error.no.tracerkey.account.found" });
                } else if (data.me.wallet.address === address) {
                    setMe(data.me);
                } else {
                    setMe(null);
                    logout({ error: "error.mismatch.account" });
                }
            }
        },
        onError: (err) => {
            const token = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
            // console.log(token);
            if (token) {
                localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
                logout();
                displaySnackBar({
                    message: formatMessage({ id: err.message || "error.auth" }),
                    type: "error",
                });
            }
        },
    });

    useEffect(() => {
        if (me) {
            tracerkeyConnect().then((res) => {
                if (me.wallet.address !== res.address) {
                    logout({ error: "error.mismatch.account" });
                    setMe(null);
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [me]);

    return (
        <UserContext.Provider
            value={{
                me,
                meId: identity?.id
                    ? {
                          email: identity?.email,
                          email_verified: identity?.verified,
                          family_name: identity?.last_name,
                          given_name: identity?.first_name,
                          name: `${identity?.first_name} ${identity?.last_name}`,
                          preferred_username: identity?.email,
                          sub: identity?.id,
                      }
                    : undefined,
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export default UserProvider;
