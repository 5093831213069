import { DEVID } from "../constants/devIdEndpoints";
import { ROUTES } from "../constants/routes";
import config from "./config";

export const oauthConfig = {
    clientId: config?.id?.clientId as string,
    authorizationEndpoint:
        `${config?.id?.hydraUrl}${DEVID.AUTHORIZATION}` as string,
    tokenEndpoint: `${config?.id?.hydraUrl}${DEVID.TOKEN}` as string,
    redirectUri: config?.id?.redirectUri as string,
    scope: "wallet organization openid offline_access",
    autoLogin: false,
    state: "this-is-a-state",
    decodeToken: true,
    logoutRedirect: `${window.location.origin}${ROUTES.SIGNIN}`,
};
